import { apiCall, objToQueryString } from "..";
import { Api } from "../../config";

const getArtistCountries = (profileId, params = {}) => {
  const parameters = {
    pageIndex: 0,
    pageSize: 20,
  };
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key];
    }
  }
  const request = {
    url:
      `${Api.baseURL}/artist/profile/${profileId}/countries?` +
      objToQueryString(parameters),
    method: "get",
  };
  return apiCall(request);
};
const getArtists = (params = {}) => {
  const parameters = {
    pageIndex: 0,
    pageSize: 20,
  };
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key];
    }
  }
  const request = {
    url: `${Api.baseURL}/artists`,
    method: "post",
    body: JSON.stringify(parameters),
  };
  return apiCall(request);
};
const getWebArtists = (params = {}) => {
  const parameters = {
    pageIndex: 0,
    pageSize: 20,
  };
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key];
    }
  }
  const request = {
    url: `${Api.baseURL}/web/artists`,
    method: "post",
    body: JSON.stringify(parameters),
  };
  return apiCall(request);
};

const syncArtistFollowing = (accessToken) => {
  const parameters = {
    accessToken: accessToken,
  };
  const request = {
    url: `${Api.baseURL}/me/sync/spotify_artist_followings`,
    method: "post",
    body: JSON.stringify(parameters),
  };
  return apiCall(request);
};

const getLineupArtists = (eventId, params = {}) => {
  const parameters = {
    pageIndex: 0,
    pageSize: 20,
  };
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key];
    }
  }
  const request = {
    url:
      `${Api.baseURL}/event/${eventId}/lineup?` + objToQueryString(parameters),
    method: "get",
  };
  return apiCall(request);
};
const getArtist = (id) => {
  const request = {
    url: `${Api.baseURL}/artist/${id}`,
    method: "get",
  };
  return apiCall(request);
};
const getArtistByProfileId = (id) => {
  const request = {
    url: `${Api.baseURL}/artist/profile/${id}`,
    method: "get",
  };
  return apiCall(request);
};
export default {
  getArtists,
  getWebArtists,
  getArtist,
  getLineupArtists,
  getArtistByProfileId,
  getArtistCountries,
  syncArtistFollowing,
};
