const initData = require('./init_data.json')
const countryData = require('./countries.json')

const getData = () => {
  return initData
}
const getCountryData = () => {
  return countryData
}

export default {
  getData,
  getCountryData,
}
