import i18next from "i18next";
import loadData from "./data";
import { formatDate } from "../helpers";
import { Constants } from "../config";
import { FestivalService } from "../api";
const groupByYear = (events, sortType) => {
  let eventSections = {};
  events.forEach((item) => {
    const year = formatDate(
      item.startDate,
      Constants.dateFormat.DATE_FORMAT_YEAR
    );
    if (eventSections[year] != null) {
      eventSections[year].push(item);
    } else {
      eventSections[year] = [];
      eventSections[year].push(item);
    }
  });
  let sections = [];
  for (const year in eventSections) {
    sections.push({
      year: year,
      title: year,
      data: eventSections[year],
    });
  }
  sections.sort(function (a, b) {
    if (a.year < b.year) {
      return sortType == Constants.dateFilterType.DATE_FILTER_PAST ? 1 : -1
    }
    if (a.year > b.year) {
      return sortType == Constants.dateFilterType.DATE_FILTER_PAST ? -1 : 1
    }
    // names must be equal
    return 0
  })

  return sections;
};

export function find(params = {}) {
  return new Promise((resolve, reject) => {
    FestivalService.getFestivals(params)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => reject(err));
  });
}
export function findWeb(params = {}) {
  return new Promise((resolve, reject) => {
    FestivalService.getFestivalsWeb(params).then((result) => {
      resolve(result.data);
    });
  });
}
export function findOne(id) {
  return new Promise((resolve, reject) => {
    FestivalService.getFestival(id)
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((err) => reject(err));
  });
}
export function findOneByProfileId(id) {
  return new Promise((resolve, reject) => {
    FestivalService.getFestivalByProfileId(id)
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((err) => reject(err));
  });
}
export default {
  find,
  findWeb,
  findOne,
  groupByYear,
  findOneByProfileId,
};
