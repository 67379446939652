import { apiCall, objToQueryString } from '..'
import { Api } from '../../config'
const getTickets = (params = {}) => {
  const parameters = {}
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key]
    }
  }
  const request = {
    url: `${Api.baseURL}/tickets?` + objToQueryString(parameters),
    method: 'get',
  }
  return apiCall(request)
}

const createTicket = (params = {}) => {
  const request = {
    url: `${Api.baseURL}/ticket`,
    method: 'put',
    body: JSON.stringify(params),
  }
  return apiCall(request)
}

const actionClick = (ticketID) => {
  const request = {
    url: `${Api.baseURL}/festival/ticket-click/${ticketID}`,
    method: 'post',
  }
  return apiCall(request)
}

export default {
  getTickets,
  createTicket,
  actionClick,
}
