import { apiCall, objToQueryString } from '..'
import { Api } from '../../config'

const getProfileFind = (params = {}) => {
  const parameters = {
    pageIndex: 0,
    pageSize: 20,
  }
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key]
    }
  }
  const request = {
    url: `${Api.baseURL}/profiles/find`,
    method: 'post',
    body: JSON.stringify(parameters),
  }
  return apiCall(request)
}

const getProfileStatistics = (params = {}) => {
  const parameters = {}
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key]
    }
  }

  const request = {
    url: `${Api.baseURL}/profiles/statistics`,
    method: 'post',
    body: JSON.stringify(parameters),
  }
  return apiCall(request)
}

const getProfileByUserId = (userId) => {
  const request = {
    url: `${Api.baseURL}/profile?userId=${userId}`,
    method: 'get',
  }
  return apiCall(request)
}

const getProfileByProfileId = (profileId) => {
  const request = {
    url: `${Api.baseURL}/profile/${profileId}`,
    method: 'get',
  }
  return apiCall(request)
}

const deleteAccount = (params = {}) => {
  const parameters = {
    email: params.email,
    password: params.password,
    notes: params.notes,
  }
  const request = {
    url: `${Api.baseURL}/delete-account`,
    method: 'put',
    body: JSON.stringify(parameters),
  }
  return apiCall(request)
}

const updatePlayerIDOneSignal = (params = {}) => {
  const parameters = {
    playerID_oneSignal: params.playerID_oneSignal,
  }
  const request = {
    url: `${Api.baseURL}/playerid-onesignal`,
    method: 'put',
    body: JSON.stringify(parameters),
  }
  return apiCall(request)
}

const validateUsername = (params = {}) => {
  const parameters = {
    username: params.username,
  }
  const request = {
    url: `${Api.baseURL}/validateUsername`,
    method: 'post',
    body: JSON.stringify(parameters),
  }
  return apiCall(request)
}

const subscribeNewsletter = (params = {}) => {
  const parameters = {
    email: params.email,
  }
  const request = {
    url: `${Api.baseURL}/subscribe-newsletter`,
    method: 'post',
    body: JSON.stringify(parameters),
  }
  return apiCall(request)
}

export default {
  getProfileFind,
  getProfileStatistics,
  getProfileByUserId,
  getProfileByProfileId,
  deleteAccount,
  updatePlayerIDOneSignal,
  validateUsername,
  subscribeNewsletter,
}
