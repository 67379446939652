import loadData from './data'
import MediaService from '../api/MediaService'

export function find(params = {}) {
  return new Promise((resolve, reject) => {
    MediaService.getAlbums(params).then((result) => {
      resolve(result.data)
    })
  })
}
export function findOne(albumId) {
  return new Promise((resolve, reject) => {
    MediaService.getAlbum(albumId).then((result) => {
      resolve(result.data.data)
    })
  })
}
export default {
  find,
  findOne,
}
