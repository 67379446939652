import { apiCall } from "..";
import { Api } from "../../config";

const getVenues = (params = {}) => {
  const parameters = {
    pageIndex: 0,
    pageSize: 20,
  };
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key];
    }
  }
  const request = {
    url: `${Api.baseURL}/find-venues`,
    method: "post",
    body: JSON.stringify(parameters),
  };
  return apiCall(request);
};
const getVenue = (id) => {
  const request = {
    url: `${Api.baseURL}/venue/${id}`,
    method: "get",
  };
  return apiCall(request);
};
export default {
  getVenues,
  getVenue,
};
