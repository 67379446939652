import { FestivalService } from "../api";

export function find(params = {}) {
  return new Promise((resolve, reject) => {
    FestivalService.getFestivals(params).then((result) => {
      resolve(result.data);
    });
  });
}
export function findWeb(params = {}) {
  return new Promise((resolve, reject) => {
    FestivalService.getFestivalsWeb(params).then((result) => {
      resolve(result.data);
    });
  });
}
export function findOne(id) {
  return new Promise((resolve, reject) => {
    FestivalService.getFestival(id).then((result) => {
      resolve(result.data.data);
    });
  });
}
export function findSpotifyFestivals(params = {}) {
  return new Promise((resolve, reject) => {
    FestivalService.getSpotifyFestivals(params).then((result) => {
      resolve(result.data);
    });
  });
}
export default {
  find,
  findWeb,
  findSpotifyFestivals,
};
