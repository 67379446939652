import { apiCall, objToQueryString } from '..'
import { Api } from '../../config'
const getMedias = (params = {}) => {
  const parameters = {
    pageIndex: 1,
    pageSize: 20,
  }
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key]
    }
  }
  const request = {
    url: `${Api.baseURL}/medias?` + objToQueryString(parameters),
    method: 'get',
  }
  return apiCall(request)
}
const getMedia = (mediaId) => {
  const request = {
    url: `${Api.baseURL}/media/${mediaId}`,
    method: 'get',
  }
  return apiCall(request)
}
const getAlbum = (albumId) => {
  const request = {
    url: `${Api.baseURL}/album/${albumId}`,
    method: 'get',
  }
  return apiCall(request)
}

const likeMedia = (isLike, mediaId) => {
  const apiParam = isLike ? 'like' : 'unlike'
  const body = {
    mediaId: mediaId,
  }
  const request = {
    url: `${Api.baseURL}/media/${apiParam}`,
    method: 'post',
    body: JSON.stringify(body),
  }
  return apiCall(request)
}

const getAlbums = (params = {}) => {
  const parameters = {
    pageIndex: 1,
    pageSize: 20,
  }
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key]
    }
  }
  const request = {
    url: `${Api.baseURL}/albums?` + objToQueryString(parameters),
    method: 'get',
  }
  return apiCall(request)
}
export default {
  getMedias,
  getMedia,
  getAlbums,
  getAlbum,
  likeMedia,
}
