import MediaService from '../api/MediaService'
import loadData from './data'

export function find(params = {}) {
  return new Promise((resolve, reject) => {
    MediaService.getMedias(params).then((result) => {
      resolve(result.data)
    })
  })
}
export function findOne(mediaId) {
  return new Promise((resolve, reject) => {
    MediaService.getMedia(mediaId).then((result) => {
      resolve(result.data.data)
    })
  })
}
export default {
  find,
  findOne,
}
