import FestivalService from "./FestivalService";
import ArtistService from "./ArtistService";
import HomeService from "./HomeService";
import TicketService from "./TicketService";
import ProfileService from "./ProfileService";
import LocationService from "./LocationService";
import TagService from "./TagService";
import VenueService from "./VenueService";
import { verifyToken } from "../helpers/jwtHelper";
import { getLocalAuth, removeAuth } from "../helpers/storageHelper";
import { Api } from "../config";
// import { _persistAuth } from "../modules/auth/redux/actions/AuthActions";
export const mockApiCall = (request, response) => {
  return new Promise((resolve, reject) => {
    resolve(response);
  });
};
export const refreshAccessToken = (refreshToken) => {
  const parameters = { refreshToken: refreshToken };
  const request = {
    url: `${Api.baseURL}/token`,
    method: "post",
    body: JSON.stringify(parameters),
  };
  const defaults = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "",
    },
  };
  return new Promise((resolve, reject) => {
    fetch(request.url, { ...defaults, ...request })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            resolve(data);
          });
        } else {
          return res.json().then((data) => {
            reject(data);
          });
        }
      })
      .catch((error) => {
        reject({});
      });
  });
};
export const getAccessToken = (isForceToRefreshToken = false) => {
  return new Promise((resolve) => {
    getLocalAuth()
      .then((auth) => {
        const { accessToken, refreshToken } = auth;
        if (verifyToken(accessToken) && !isForceToRefreshToken) {
          resolve(accessToken);
        } else {
          if (refreshToken) {
            refreshAccessToken(refreshToken)
              .then((response) => {
                auth.accessToken = response.data;
                // _persistAuth(auth);
                resolve(response.data);
              })
              .catch((err) => {
                resolve();
              });
          } else {
            resolve();
          }
        }
      })
      .catch(() => {
        resolve();
      });
  });
};
export const apiCallFormData = (url, formData) => {
  return new Promise((resolve, reject) => {
    getAccessToken()
      .then((accessToken) => {
        const request = {
          url: url,
          method: "post",
          body: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        };

        return fetch(request.url, request)
          .then((res) => {
            if (res.status === 204) {
              resolve({});
            } else if (res.ok) {
              return res.json().then((data) => {
                resolve({ data });
              });
            } else if (res.status === 401) {
              removeAuth();
              reject({});
            } else {
              return res.json().then((data) => {
                reject({ data });
              });
            }
          })
          .catch((error) => {
            console.error("error", error);
            reject({});
          });
      })
      .catch((err) => {
        console.error("error", err);
        reject({});
      });
  });
};
export const apiCall = (request) => {
  return new Promise((resolve, reject) => {
    getAccessToken()
      .then((accessToken) => {
        const defaults = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        };

        return fetch(request.url, { ...defaults, ...request })
          .then((res) => {
            if (res.status === 204) {
              resolve({});
            } else if (res.ok) {
              return res.json().then((data) => {
                resolve({ data });
              });
            } else if (res.status === 401) {
              removeAuth();
              reject({});
            } else {
              return res.json().then((data) => {
                console.error("error", data);
                reject({ data });
              });
            }
          })
          .catch((error) => {
            console.error("error", error);
            reject({});
          });
      })
      .catch((err) => {
        console.error("error", err);
        reject({});
      });
  });
};
export function objToQueryString(obj) {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(
      encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
    );
  }
  return keyValuePairs.join("&");
}

export function isCloseToBottom({
  layoutMeasurement,
  contentOffset,
  contentSize,
}) {
  const paddingToBottom = 20;
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
}

export {
  FestivalService,
  ArtistService,
  HomeService,
  TicketService,
  ProfileService,
  LocationService,
  TagService,
  VenueService,
};
