import { apiCall, objToQueryString } from '..'
import { Api } from '../../config'

const getProfileTags = (params = {}) => {
  const request = {
    url: `${Api.baseURL}/profile_tags?` + objToQueryString(params),
    method: 'get',
  }
  return apiCall(request)
}
const updateProfileTags = (params = {}) => {
  const request = {
    url: `${Api.baseURL}/profile_tags`,
    method: 'post',
    body: JSON.stringify(params),
  }
  return apiCall(request)
}

export default {
  getProfileTags,
  updateProfileTags,
}
