import { apiCall, objToQueryString } from "..";
import { Api } from "../../config";

const getSpotifyFestivals = (params = {}) => {
  const parameters = {
    pageIndex: 0,
    pageSize: 100,
  };
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key];
    }
  }
  const request = {
    url: `${Api.baseURL}/spotify-related-festivals`,
    method: "post",
    body: JSON.stringify(parameters),
  };
  return apiCall(request);
};

const getFestivals = (params = {}) => {
  const parameters = {
    pageIndex: 0,
    pageSize: 20,
  };
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key];
    }
  }
  const request = {
    url: `${Api.baseURL}/festivals`,
    method: "post",
    body: JSON.stringify(parameters),
  };
  return apiCall(request);
};
const getFestivalsWeb = (params = {}) => {
  const parameters = {
    pageIndex: 0,
    pageSize: 20,
  };
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key];
    }
  }
  const request = {
    url: `${Api.baseURL}/web/festivals`,
    method: "post",
    body: JSON.stringify(parameters),
  };
  return apiCall(request);
};
const getFestival = (id) => {
  const request = {
    url: `${Api.baseURL}/festival/${id}`,
    method: "get",
  };
  return apiCall(request);
};
const getFestivalByProfileId = (profileId) => {
  const request = {
    url: `${Api.baseURL}/festival/profile/${profileId}`,
    method: "get",
  };
  return apiCall(request);
};

const getAmenitiesByEventId = (eventId) => {
  const parameters = {
    eventId: eventId,
  };
  const request = {
    url: `${Api.baseURL}/amenities?` + objToQueryString(parameters),
    method: "get",
  };
  return apiCall(request);
};
const getInsightByEventId = (eventId) => {
  const parameters = {
    eventId: eventId,
  };
  const request = {
    url: `${Api.baseURL}/insight?` + objToQueryString(parameters),
    method: "get",
  };
  return apiCall(request);
};
export default {
  getFestivals,
  getFestivalsWeb,
  getFestival,
  getFestivalByProfileId,
  getAmenitiesByEventId,
  getInsightByEventId,
  getSpotifyFestivals,
};
