import { TagService } from '../api'
import { Constants } from '../config'

const getTagData = (tags, selectedTags) => {
  const selectedTagIds = selectedTags.map((x) => x.id)
  return tags.map((tag) => {
    if (selectedTagIds.includes(tag.id)) {
      return {
        ...tag,
        isSelected: true,
      }
    } else {
      return {
        ...tag,
        isSelected: false,
      }
    }
  })
}
export function getMusicTags(params = {}) {
  return new Promise((resolve, reject) => {
    TagService.getProfileTags({
      category: Constants.tagCategory.TAG_CATEGORY_MUSIC,
      ...params,
    })
      .then((result) => {
        const { tags, selectedTags } = result.data.data
        resolve(getTagData(tags, selectedTags))
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export function getVenueTags(params = {}) {
  return new Promise((resolve, reject) => {
    TagService.getProfileTags({
      category: Constants.tagCategory.TAG_CATEGORY_VENUE,
      ...params,
    })
      .then((result) => {
        const { tags, selectedTags } = result.data.data
        resolve(getTagData(tags, selectedTags))
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export function getEventTags(params = {}) {
  return new Promise((resolve, reject) => {
    TagService.getProfileTags({
      category: Constants.tagCategory.TAG_CATEGORY_EVENT_TYPE,
      ...params,
    })
      .then((result) => {
        const { tags, selectedTags } = result.data.data
        resolve(getTagData(tags, selectedTags))
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export default {
  getMusicTags,
  getVenueTags,
  getEventTags,
}
