import { VenueService } from "../api";

function find(params = {}) {
  return new Promise((resolve, reject) => {
    VenueService.getVenues(params).then((result) => {
      resolve(result.data);
    });
  });
}
function findOne(id) {
  return new Promise((resolve, reject) => {
    VenueService.getVenue(id).then((result) => {
      resolve(result.data.data);
    });
  });
}

export default {
  find,
  findOne,
};
