import i18next from "i18next";
import { ArtistService } from "../api";
import { Constants } from "../config";
import { getSpecialist } from "../helpers/formatHelpers";
import loadData from "./data";

const getGenreTitle = (genre) => {
  return genre?.toUpperCase();
};
const groupByGenre = (artists) => {
  let genreGroups = {};
  artists.forEach((item) => {
    if (genreGroups[item.genre] != null) {
      genreGroups[item.genre].push(item);
    } else {
      genreGroups[item.genre] = [];
      genreGroups[item.genre].push(item);
    }
  });
  let groups = [];
  for (const genre in genreGroups) {
    const count = genreGroups[genre]?.length;
    const percentage = Math.floor((count / artists.length) * 100);
    groups.push({
      genre: genre,
      title: getGenreTitle(genre),
      suffix: percentage + "%",
      data: [
        {
          list: genreGroups[genre],
        },
      ],
    });
  }
  return groups;
};

export function findLineup(eventId, params = {}) {
  return new Promise((resolve, reject) => {
    ArtistService.getLineupArtists(eventId, params).then((result) => {
      const response = {
        ...result.data,
        data: result.data.data.map((item) => {
          return {
            ...item,
            name: item.displayName,
            thumbnailImage: item.avatar || "Local:defaultArtist",
            avatar: item.avatar || "Local:defaultArtist",
            isHeadliner: item.isHeadliner === 1,
            isActive: item.isFollow,
          };
        }),
      };
      resolve(response);
    });
  });
}
export function find(params = {}) {
  return new Promise((resolve, reject) => {
    ArtistService.getArtists(params).then((result) => {
      const response = {
        ...result.data,
        data: result.data.data.map((item) => {
          const specialist = getSpecialist(item.genres);
          return {
            ...item,
            username: item.username,
            isActive: item.isHeadliner === 1,
            thumbnailImage: item.avatar || "Local:defaultArtist",
            avatar: item.avatar || "Local:defaultArtist",
            specialist: specialist,
          };
        }),
      };
      resolve(response);
    });
  });
}
export function findWeb(params = {}) {
  return new Promise((resolve, reject) => {
    ArtistService.getWebArtists(params).then((result) => {
      const response = {
        ...result.data,
        data: result.data.data.map((item) => {
          return {
            ...item,
            specialist: item.genre,
          };
        }),
      };
      resolve(response);
    });
  });
}
export function findOneByProfileId(id) {
  return new Promise((resolve, reject) => {
    ArtistService.getArtistByProfileId(id).then((result) => {
      const item = result.data.data;
      const specialist = getSpecialist(item.genres);
      resolve({
        specialist: specialist,
        ...item,
        thumbnailImage: item.avatar,
        isHeadliner: item.isHeadliner === 1,
        isActive: item.isFollow,
      });
    });
  });
}
export function findOne(id) {
  return new Promise((resolve, reject) => {
    ArtistService.getArtist(id).then((result) => {
      const item = result.data.data;
      const specialist = getSpecialist(item.genres);
      resolve({
        specialist: specialist,
        ...item,
        displayName: item.displayName,
        thumbnailImage: item.avatar,
        isHeadliner: item.isHeadliner === 1,
        isActive: item.isFollow,
      });
    });
  });
}

export default {
  find,
  findWeb,
  findOne,
  groupByGenre,
  findLineup,
  findOneByProfileId,
};
