import { HomeService } from "../api";
import { Constants } from "../config";

export function find(params = {}) {
  return new Promise((resolve, reject) => {
    HomeService.getHomeCategories(params).then((result) => {
      resolve(result.data.data);
    });
  });
}
export function getConstantCategories() {
  return new Promise((resolve, reject) => {
    resolve([
      {
        id: Constants.categoryType.CATEGORY_VENUE,
        title: "Venues",
        slug: "venues",
      },
      {
        id: Constants.categoryType.CATEGORY_ROOFTOP,
        title: "Rooftops",
        slug: "rooftops",
      },
      {
        id: Constants.categoryType.CATEGORY_SPA,
        title: "Spas",
        slug: "spas",
      },
      {
        id: Constants.categoryType.CATEGORY_VENUE_BEACHFUL,
        title: "Beach Clubs",
        slug: "beach-clubs",
      },
      {
        id: Constants.categoryType.CATEGORY_PREFAB_COMPANY,
        title: "Companies",
        slug: "companies",
      },
      {
        id: Constants.categoryType.CATEGORY_PREFAB_HOMES,
        title: "Homes",
        slug: "homes",
      },
      {
        id: Constants.categoryType.CATEGORY_ZOO,
        title: "Zoos",
        slug: "zoos",
      },
      {
        id: Constants.categoryType.CATEGORY_ANIMAL,
        title: "Animals",
        slug: "animals",
      },
      {
        id: Constants.categoryType.CATEGORY_PARKFUL,
        title: "Parks",
        slug: "parks",
      },
      {
        id: Constants.categoryType.CATEGORY_CASINO,
        title: "Casinos",
        slug: "casinos",
      },
      {
        id: Constants.categoryType.CATEGORY_CASTLE,
        title: "Castles",
        slug: "castles",
      },
      {
        id: Constants.categoryType.CATEGORY_RESORT,
        title: "Resorts",
        slug: "resorts",
      },
      {
        id: Constants.categoryType.CATEGORY_APARTHOTEL,
        title: "Aparthotels",
        slug: "aparthotels",
      },
      {
        id: Constants.categoryType.CATEGORY_VENUE_COCKTAIL,
        title: "Bars",
        slug: "bars",
      },
      {
        id: Constants.categoryType.CATEGORY_FESTIVAL,
        title: "Festivals",
        slug: "festivals",
      },
      {
        id: Constants.categoryType.CATEGORY_EVENT,
        title: "Events",
        slug: "events",
      },
      {
        id: Constants.categoryType.CATEGORY_ARTIST,
        title: "Artists",
        slug: "artists",
      },
      {
        id: Constants.categoryType.CATEGORY_OTHER,
        title: "Others",
        slug: "others",
      },
    ]);
  });
}
export default {
  find,
  getConstantCategories,
};
