import { apiCall } from '..'
import { Api } from '../../config'
const getHomeCategories = (params = {}) => {
  const parameters = {}
  for (const key in params) {
    if (params[key]) {
      parameters[key] = params[key]
    }
  }
  const request = {
    url: `${Api.baseURL}/home`,
    method: 'post',
    body: JSON.stringify(parameters),
  }
  return apiCall(request)
}
export default {
  getHomeCategories,
}
