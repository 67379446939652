import { ArtistService } from '../api'
import loadData from './data'

export function findArtistCountries(profileId, params = {}) {
  return new Promise((resolve, reject) => {
    ArtistService.getArtistCountries(profileId, params).then((result) => {
      const data = result.data
      data.data = data.data.map((item) => {
        return {
          ...item,
          displayName: item.name,
        }
      })
      resolve(result.data)
    })
  })
}
export function findOne(params = {}) {
  return new Promise((resolve, reject) => {
    const initData = loadData.getData()
    let item = initData.countries[0]
    resolve(item)
  })
}
export default {
  findArtistCountries,
  findOne,
}
